var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-list-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "我的账单",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getBills
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.bills, function (bill, index) {
    return _c('div', {
      key: index,
      staticClass: "bill-item"
    }, [_c('van-cell', {
      attrs: {
        "title": "账单状态",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(bill);
        }
      }
    }, [_c('van-tag', {
      attrs: {
        "type": bill.status ? 'success' : 'warning'
      }
    }, [_vm._v(_vm._s(bill.status ? '已完成' : '未完成'))])], 1), bill.status === 0 && bill.check_msg ? _c('van-cell', {
      attrs: {
        "title": "系统消息",
        "value": bill.check_msg
      }
    }) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": "账单日期",
        "value": bill.bill_date
      }
    }), _c('van-cell', {
      attrs: {
        "title": "交账时间",
        "value": bill.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "value-class": "amount-color",
        "title": "结算金额",
        "value": "\uFFE5".concat(bill.real_amount)
      }
    })], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }