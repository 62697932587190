<template>
  <div class="bill-list-container">
    <my-nav-bar
      title="我的账单"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-list
        v-model="loading"
        :finished="finished"
        class="list"
        @load="getBills"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <div v-for="(bill, index) in bills" :key="index" class="bill-item">
          <van-cell title="账单状态" is-link center @click="toDetailView(bill)">
            <van-tag :type="bill.status ? 'success' : 'warning'">{{ bill.status ? '已完成' : '未完成' }}</van-tag>
          </van-cell>
          <van-cell v-if="bill.status === 0 && bill.check_msg" title="系统消息" :value="bill.check_msg" />
          <van-cell title="账单日期" :value="bill.bill_date" />
          <van-cell title="交账时间" :value="bill.created_at" />
          <van-cell value-class="amount-color" title="结算金额" :value="`￥${bill.real_amount}`" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import billApi from '@/api/bill'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'BillList',
  components: { myNavBar },
  data() {
    return {
      bills: [],
      page: 0,
      limit: 10,
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getBills() {
      this.page++
      const params = {
        page: this.page,
        limit: this.limit
      }
      billApi.list(params).then(res => {
        this.bills = this.bills.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.limit
        this.showEmpty = this.bills.length === 0
      })
    },
    toDetailView(bill) {
      this.$router.push({
        path: 'bill',
        query: {
          bill_id: bill.id,
          type: 'detail'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .bill-item {
    margin-bottom: 10px;
  }
  .bill-item:nth-last-child(2) {
    margin-bottom: 0;
  }
</style>
